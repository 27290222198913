<article class="results" *ngIf="selectedYear">
  <header>
    <h1 class="results__header">OUR RESULT - JEE MAINS</h1>
  </header>
  <div class="container" *ngIf="filterdDisplayResults">
    <div class="row">
      <div class="col">
        <!-- Col 1: Results filter -->
        <div class="d-none d-md-block results__filter__bg-fix">
          <!-- Dummy div for results filter for desktop -->
        </div>
        <div class="results__filter">
          <aside>
            <div class="results-form-control">
              <!-- Sort by filter form control -->
              <label for="results-sort-by" class="results__filter__label"
                >Sort by</label
              >
              <div class="results-form-control-area">
                <div class="results__filter__dropdown">
                  <select
                    id="results-sort-by"
                    (change)="dropDownChanged($event.target.value)"
                    [ngModel]="selectedYear"
                  >
                    <option
                      [ngValue]="item"
                      *ngFor="let item of supportedYears; let i = index"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="results-form-control">
              <!-- Exam type filter form control -->
              <label class="results__filter__label">Exam type</label>
              <div style="padding: 12px 14px" class="results-form-control-area">
                <ng-container *ngFor="let item of supportedExamType;let i = index">
                  <div class="radio" *ngIf="displayResults[selectedYear][item.id].length != 0">
                    <input
                      id="radio-{{ i }}"
                      name="radio"
                      type="radio"
                      [checked]="item.id === selectedExamType"
                      (change)="examTypeSelected(item.id)"
                    />
                    <label for="radio-{{ i }}" class="radio-label">{{
                      item.name
                    }}</label>
                  </div>
                </ng-container>
                <!-- <div
                  class="result__filter_radio"
                  *ngFor="let item of supportedExamType"
                >
                  <input
                    type="radio"
                    id="result-type-{{ item.id }}"
                    name="results-exam-type"
                    [value]="item.id"
                    [checked]="item.id === selectedExamType"
                    (change)="examTypeSelected(item.id)"
                  />
                  <label for="result-type-{{ item.id }}">{{ item.name }}</label>
                </div> -->
                <!-- <div class="result__filter_radio">
                  <input
                    type="radio"
                    id="result-type-jee-adv"
                    name="results-exam-type"
                  />
                  <label for="result-type-jee-adv">JEE Advance</label>
                </div> -->
              </div>
            </div>
          </aside>
        </div>
      </div>
      <div class="col-md-7">
        <!-- Col 2: Result data -->
        <section class="results__main_content">
          <!-- Top 3 Students section -->
          <header>
            <h2 class="topers__result__header">TOP 3 STUDENTS</h2>
          </header>
          <div class="container">
            <div class="row px-1">
              <div
                class="col-xs-4 col-lg-4 col-sm-4 col-md-4"
                *ngFor="let item of displayResults[selectedYear][selectedExamType].slice(0, 3)"
              >
                <div class="result__slide">
                  <img
                    alt="1000+ alumni in IIT"
                    src="../../assets/images/static/alumnais.png"
                  />
                  <div class="student_ranking mx-5" *ngIf="item.exam_type=='advn'">AIR-{{ item.rank }}</div>
                  <div class="student_ranking mx-5" *ngIf="item.exam_type!='advn'">{{ item.rank }}</div>
                  <div class="student__name m-1">
                    {{ item.name }}
                  </div>
                  <!-- <div class="student__percentage">99.50 %</div> -->
                </div>
              </div>
              <!-- <div class="col-xs-4 col-lg-4 col-sm-4 col-md-4">
                <div class="result__slide">
                  <img
                    alt="1000+ alumni in IIT"
                    src="../../assets/images/students/director-image.png"
                  />
                  <div class="student_ranking mx-5">AIR 1</div>
                  <div class="student__name m-1">
                    Sushant Sachadeva Sachadeva
                  </div>
                  <div class="student__percentage">99.50 %</div>
                </div>
              </div>
              <div class="col-xs-4 col-lg-4 col-sm-4 col-md-4">
                <div class="result__slide">
                  <img
                    alt="1000+ alumni in IIT"
                    src="../../assets/images/students/director-image.png"
                  />
                  <div class="student_ranking mx-5">AIR 1</div>
                  <div class="student__name m-1">Sushant Sachadeva</div>
                  <div class="student__percentage">99.50 %</div>
                </div>
              </div> -->
            </div>
          </div>
        </section>
        <section>
          <header>
            <h2 class="cong__header">CONGRATULATIONS TO ALL !!</h2>
          </header>
          <div class="results__grid">
            <div class="row">
              <div class="col-xs-12 col-lg-12 col-md-12 my-4">
                <table class="table">
                  <thead class="thead-light">
                    <tr *ngIf="selectedExamType=='advn'">
                      <th scope="col">S.No</th>
                      <th scope="col">Rank</th>
                      <th scope="col">Name</th>
                    </tr>
                    <tr *ngIf="selectedExamType=='main'">
                      <th scope="col">S.No</th>
                      <th scope="col">Percentile/Score/Rank</th>
                      <th scope="col">Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of displayResults[selectedYear][selectedExamType]">
                      <td scope="row">{{ item.id }}</td>
                      <td>{{ item.rank }}</td>
                      <td>{{ item.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="col">
        <!-- Col 3: Ads -->
        <aside>
          <div class="results__ads">
            <img src="../../assets/images/results/results-ad.png" routerLink="/forms/common/register_now" style="cursor: pointer"/>
          </div>
          <div class="results__ads">
            <img src="../../assets/images/results/results-ad-2.png" routerLink="/forms/common/register_now" style="cursor: pointer"/>
          </div>
        </aside>
      </div>
    </div>
  </div>
</article>
